
.spark-line[data-v-e7443980] {
	text-align: right;
	position: relative;
}
.spark-line_outer[data-v-e7443980] {
	width: var(--e7443980-width);
	height: 26px;
	background: var(--e7443980-background);
}
.spark-line_inner[data-v-e7443980] {
	position: absolute;
	top: 0;
	left: 0;
}
