.diary-info[data-v-bb05b8ea] {
  overflow: hidden;
  min-height: 300px;
  height: auto;
}
.loading[data-v-bb05b8ea] {
  height: 400px;
}
.report-info-container[data-v-bb05b8ea] {
  border: 1px solid #ebebeb;
  border-radius: 8px;
  margin-bottom: 24px;
  padding: 8px;
}
.report-info_title[data-v-bb05b8ea] {
  text-align: left;
  font-weight: bolder;
  font-size: 16px;
}
[data-v-bb05b8ea] .report-info_label {
  text-align: left;
  font-weight: bolder;
  margin-top: 8px;
}
