@media (max-width: 768px) {
.day-report_nav[data-v-52e7bd38] {
    display: none;
}
}
.day-report-container[data-v-52e7bd38] {
  display: flex;
  height: calc(100vh - 60px);
  flex-direction: column;
  background: #fff;
}
.day-report-container .tools[data-v-52e7bd38] {
  padding: 8px;
}
.day-report-container .main[data-v-52e7bd38] {
  display: flex;
  flex: 1;
  overflow: hidden;
}
.day-report-container .main .day-report_board[data-v-52e7bd38] {
  flex: 1;
  border-right: 1px solid #d9d9d9;
  height: 100%;
}
.day-report-container .main .day-report_nav[data-v-52e7bd38] {
  width: 300px;
}
