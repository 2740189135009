.fileBoxWrapper[data-v-2f839a63] {
  display: inline-block;
  width: 100px;
  height: 100px;
}
.imgbox[data-v-2f839a63] {
  display: block;
  width: 84px;
  height: 84px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 4px;
}
.fileBox[data-v-2f839a63] {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  padding: 8px 8px 8px 8px;
  border: 1px solid white;
  border-radius: 6px;
  cursor: pointer;
}
.fileBox .name[data-v-2f839a63] {
  width: 116px;
  margin-top: 8px;
  overflow: hidden;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  word-wrap: break-word;
  word-break: break-all;
}
.fileBox .dropdown-container[data-v-2f839a63] {
  position: absolute;
  top: 6px;
  right: 0px;
  display: block;
}
.fileBox .iconBall[data-v-2f839a63] {
  position: absolute;
  width: 32px;
  height: 32px;
  color: white;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  background-color: #e6f7ff;
  border-radius: 16px;
  padding: 0;
  transform: rotate(-90deg);
}
.fileBox .iconBall.left[data-v-2f839a63] {
  top: 6px;
  left: 6px;
  display: block;
  opacity: 0;
  background-color: #91d5ff;
}
.fileBox .iconBall.right[data-v-2f839a63] {
  top: 6px;
  right: 6px;
  display: block;
  opacity: 0;
  background-color: #91d5ff;
}
.fileBox[data-v-2f839a63]:hover {
  background-color: #f0f6ff;
  border: 1px solid #f0f6ff;
}
.fileBox:hover .iconBall.right[data-v-2f839a63] {
  display: block;
  opacity: 0.8;
}
.fileBox:hover .iconBall.right[data-v-2f839a63]:hover {
  background-color: #1890ff;
  opacity: 1;
}
