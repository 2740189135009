.title[data-v-605a6464] {
  height: 75px;
  display: flex;
  align-items: center;
  line-height: 75px;
}
.toggle-btn[data-v-605a6464] {
  display: flex;
  margin-right: var(--margin-sm);
  cursor: pointer;
  --icon-stroke: var(--gray-500);
}
.toggle-btn .sidebar-toggle-placeholder[data-v-605a6464] {
  transition: all 0.5s ease-in-out;
}
.toggle-btn .sidebar-toggle-icon[data-v-605a6464] {
  transition: all 0.5s ease-in-out;
  display: none;
}
