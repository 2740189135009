
.name-container[data-v-8a8f1afa] {
	display: flex;
	align-items: center;
}
.name-placeholder[data-v-8a8f1afa] {
	flex: 1;
}
.remark-icon[data-v-8a8f1afa] {
	float: right;
	font-size: 12px;
}
